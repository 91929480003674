@tailwind base;
@tailwind components;
@tailwind utilities;

.section-background {
    @apply rounded-lg bg-gray-100 dark:bg-gray-800 shadow-lg
}

.section-title {
    @apply p-4 bg-blue-400 dark:bg-blue-800 mb-2 rounded-t-lg font-bold text-white dark:text-gray-300
}

.section-title-floating {
    @apply rounded-lg p-4 bg-blue-400 dark:bg-blue-800 mb-2 shadow-lg font-bold text-white dark:text-gray-300
}

.section-content {
    @apply pt-2 pb-8 mb-8 grid text-center overflow-y-scroll
}

.section-content-floating {
    @apply p-4 rounded-lg bg-gray-100 dark:bg-gray-800 shadow-lg max-h-96 overflow-y-auto
}

.section-heading {
    @apply text-sm font-bold dark:text-gray-300
}

.section-subheading {
    @apply text-base font-bold text-center dark:text-gray-300
}

.text-normal {
    @apply dark:text-gray-300
}

.table-container {
    @apply w-full text-center shadow-xl mt-4 bg-gray-200 dark:bg-gray-900 rounded-b-lg table-auto
}

.table-title {
    @apply bg-blue-500 dark:bg-blue-900 text-white py-3 dark:text-gray-300
}

.table-data {
    @apply text-normal py-2
}

.log-content {
    @apply font-mono text-xs whitespace-pre-wrap text-left max-h-96 rounded-md shadow-lg
}
